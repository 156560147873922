import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  inject,
  Injector,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { getNgControl } from '@shared/forms/utils/get-ng-control';
import { KeyValue } from '@core/models/keyValue.model';
import { isString } from '@shared/utils/is-string';
import { KeyDescription } from '@shared/model/key-description';

import { objectHasProp } from '@core/helpers/hasOwnProperty-helper';
import { BaseComponent } from '@shared/components/base/base.component';
import { isNumber } from '@shared/utils/is-number';
import { CustomLoaderComponent } from '@shared/spinners/custom-loader/custom-loader.component';
import { FormErrorComponent } from '@shared/forms/form-error/form-error.component';
import { GetOptionLabelPipe } from '@shared/forms/pipes/get-option-label.pipe';
import { ComponentChanges } from '@shared/helpers/component-changes';
import { CustomSelectModule } from '@root/app/shared/custom-modules/custom-select-module.module';
import { isBoolean } from 'lodash-es';

type ValueType =
  | KeyValue
  | KeyValue<number>
  | KeyDescription<number>
  | KeyDescription
  | string
  | KeyDescription<boolean>
  | undefined;

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CustomSelectModule,
    MatIconModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    CustomLoaderComponent,
    FormErrorComponent,
    GetOptionLabelPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomSelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    },
  ],
})
export class CustomSelectComponent
  extends BaseComponent
  implements ControlValueAccessor, Validator, OnInit, OnChanges
{
  private injector = inject(Injector);

  searchable = input(true);
  @Input() optionLabel: 'value' | 'description' = 'value';
  @Input() inputId = '';
  @Input() inputLabel!: string;
  @Input() isReadOnly = false;
  @Input() inputPlaceHolder = 'shared.search';
  @Input() hideClearIcon = false;
  @Input() hideErrorMessage = false;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  private _isLoading = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() set isLoading(value: boolean) {
    this.isReadOnly = value;
    this._isLoading = value;
  }

  @Input() options:
    | KeyValue[]
    | KeyValue<number>[]
    | KeyDescription<number>[]
    | KeyDescription<boolean>[]
    | KeyDescription[]
    | null = [];
  @Input() emitSelectedOptionAsObject = false;

  @ContentChild('labelTemplate') labelTemplate!: TemplateRef<void>;

  value: ValueType = undefined;
  @Output() valueChanged = new EventEmitter();

  protected searchCtrl: FormControl = new FormControl(null);
  filteredOptions = new BehaviorSubject<
    unknown & { key: string | number | boolean }[]
  >([]);
  disabled = false;

  protected control?: FormControl;

  ngOnChanges(changes: ComponentChanges<CustomSelectComponent>) {
    if (changes.options) {
      this._onOptionsChange();
    }
  }

  ngOnInit(): void {
    this.control = getNgControl(this.injector);

    //track search change
    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroySubject))
      .subscribe((searchValue: string) => {
        const filtered = [];
        if (this.options) {
          // ٍsince options are an union array then we can't use find or filter
          for (const item of this.options) {
            if (
              objectHasProp(item, this.optionLabel) &&
              item[this.optionLabel]
                ?.toLowerCase()
                ?.includes(searchValue.toLowerCase())
            )
              filtered.push(item);
          }
        }
        this.filteredOptions.next(filtered);
      });
  }

  protected compareWithFn = (a: ValueType, b: ValueType) => {
    if (isObjectWithKey(a) && isObjectWithKey(b)) {
      return a.key === b.key;
    }
    return a === b;
  };

  private _onOptionsChange() {
    this.filteredOptions.next(this.options ?? []);
    this._setValue(this.value);
  }

  // eslint-disable-next-line
  onChange = (_val: ValueType) => {};
  // eslint-disable-next-line
  onTouched = () => {};

  validate(control: AbstractControl): ValidationErrors | null {
    return control?.valid ? null : { invalid: true };
  }

  writeValue(val: ValueType) {
    this._setValue(val);
  }

  registerOnChange(onChange: (_val: unknown) => void) {
    this.onChange = onChange;
  }

  registerOnTouched = (fn: () => void) => {
    this.onTouched = fn;
  };

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onBlurInput() {
    this.onTouched();
  }

  onClear(event: MouseEvent) {
    event.stopPropagation();
    this.onTouched();
    this._clearValue();
  }

  private _clearValue() {
    this._setValue(undefined);
    this._emitValue(undefined);
  }

  private _getSelectedOption(val: ValueType) {
    const keyToCompare =
      isString(val) || isNumber(val) || isBoolean(val) ? val : val?.key;
    if (this.options) {
      for (const option of this.options) {
        if (option.key === keyToCompare) return option;
      }
    }
    return val;
  }

  onSelectionChange(val: ValueType) {
    this._setValue(val);
    this._emitValue(val);
  }

  private _setValue(val: ValueType) {
    if (!val) this.value = undefined;
    if (this.emitSelectedOptionAsObject) {
      const value = val as KeyValue;
      this.value = this._getSelectedOption(value);
    } else {
      this.value = val;
    }
  }

  private _emitValue(val: ValueType) {
    if (this.emitSelectedOptionAsObject) {
      const value = val as KeyValue;
      this.onChange(this._getSelectedOption(value));
      this.valueChanged.emit(this._getSelectedOption(value));
    } else {
      this.onChange(val);
      this.valueChanged.emit(val);
    }
  }

  getLabel(): string {
    if (
      !!this.value &&
      this.emitSelectedOptionAsObject &&
      typeof this.value == 'object' &&
      objectHasProp(this.value, this.optionLabel)
    ) {
      return this.value?.[this.optionLabel];
    }
    if (isString(this.value) || isNumber(this.value) || isBoolean(this.value)) {
      const selectedOption = this._getSelectedOption(this.value);
      if (selectedOption && objectHasProp(selectedOption, this.optionLabel))
        return selectedOption[this.optionLabel] ?? '';
    }
    return '';
  }
}

const isObjectWithKey = (val: ValueType): val is KeyDescription | KeyValue => {
  return !!val && typeof val === 'object' && objectHasProp(val, 'key');
};
