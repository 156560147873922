@if (control) {
  <div class="a-custom-input-wrapper type-select w-full">
    <mat-form-field
      [appearance]="appearance"
      class="w-full"
    >
      <mat-label>
        <ng-container
          *ngTemplateOutlet="
            labelTemplate ? labelTemplate : labelDefaultTemplate
          "
        />
        <ng-template #labelDefaultTemplate>
          {{ inputLabel ?? inputPlaceHolder | translate }}
        </ng-template>
      </mat-label>
      @if (isLoading) {
        <app-custom-loader />
      }
      <mat-select
        #elRef
        [id]="inputId"
        (closed)="onBlurInput()"
        [ngModel]="value"
        (valueChange)="onSelectionChange($event)"
        [disabled]="isReadOnly || disabled"
        [placeholder]="inputPlaceHolder | translate"
        [compareWith]="compareWithFn"
      >
        <mat-select-trigger>{{ getLabel() | translate }}</mat-select-trigger>
        @if (searchable()) {
          <mat-option>
            <ngx-mat-select-search
              [formControl]="searchCtrl"
              [placeholderLabel]="inputPlaceHolder | translate"
            >
              <span ngxMatSelectNoEntriesFound>
                {{ 'shared.noItemsLabel' | translate }}
              </span>
            </ngx-mat-select-search>
          </mat-option>
        }
        @for (option of filteredOptions | async; track option) {
          <mat-option
            [value]="emitSelectedOptionAsObject ? option : option.key"
          >
            {{ option | getOptionLabel: optionLabel | translate }}
          </mat-option>
        }
      </mat-select>

      <ng-container matSuffix>
        @if (!hideClearIcon && value && !isReadOnly && !disabled) {
          <button
            (click)="onClear($event)"
            aria-label="clear"
            mat-icon-button
            type="button"
          >
            <mat-icon color="primary">close</mat-icon>
          </button>
        }
      </ng-container>
    </mat-form-field>
    @if (control && !hideErrorMessage) {
      <app-form-error [control]="control" />
    }
  </div>
}
