import { Pipe, PipeTransform } from '@angular/core';
import { resolveFieldData } from '@shared/utils/object-utils';

@Pipe({
  name: 'getOptionLabel',
  standalone: true,
})
export class GetOptionLabelPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(option: any, optionLabel: string): any {
    return optionLabel
      ? resolveFieldData(option, optionLabel)
      : option && option.label !== undefined
        ? option.label
        : option;
  }
}
