// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resolveFieldData(data: any, field: any): any {
  if (data && field) {
    if (isFunction(field)) {
      return field(data);
    } else if (field.indexOf('.') == -1) {
      return data[field];
    } else {
      const fields: string[] = field.split('.');
      let value = data;
      for (let i = 0, len = fields.length; i < len; ++i) {
        if (value == null) {
          return null;
        }
        value = value[fields[i]];
      }
      return value;
    }
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFunction(obj: any) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}
